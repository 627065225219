
import { defineComponent } from 'vue'

import DiscordProfile from '@/components/DiscordProfile.vue'
import TicketDisplay from '@/components/TicketsDisplay.vue'

import { DiscordProfileData } from '@/io/discord'
import { DatabaseBase } from '@/io/database';


export default defineComponent({
    name: 'MainInterface',

    components: {
        DiscordProfile,
        TicketDisplay
    },

    props: {
        profile: {
            type: DiscordProfileData,
            required: true
        },
        db: {
            type: DatabaseBase,
            required: true
        }
    }
});
