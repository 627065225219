
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'DiscordProfile',

    props: {
        tickets: {
            type: Number,
            required: true
        }
    }
})
