
import { defineComponent } from 'vue'


export default defineComponent({
    name: 'GeneralShopPreview',

    props: {
        name: {
            type: String,
            required: true
        },
        desc: {
            type: String,
            required: true
        },
        type: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            required: true
        },
        price: {
            type: Number,
            required: true
        },
        isOwned: {
            type: Boolean,
            required: true
        }
    },

    watch: {
        icon: function() {
            this.buying = false
        }
    },

    methods: {
        bought: function() {
            this.buying = true
            
            const typeCached = this.type
            const iconCached = this.icon
            const priceCached = this.price

            setTimeout(() => {
                this.buying = false
                this.$emit('item-bought', typeCached, iconCached, priceCached)
            }, 1000)
        }
    },

    data() {
        return {
            buying: false
        }
    }
})
