import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    width: "100",
    height: "100",
    onMouseover: _ctx.onMouseOver,
    onMouseout: _ctx.onMouseOut,
    class: _normalizeClass(['item-button', _ctx.rarityName, { 'hover': _ctx.hovering || _ctx.selected, 'selected': _ctx.selected }])
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_img, {
        class: "iconImg",
        src: require(`../assets/img/items/${_ctx.type}/${_ctx.icon}.png`)
      }, null, 8, ["src"]),
      _createVNode(_component_v_tooltip, {
        activator: "parent",
        anchor: "bottom"
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(_ctx.name), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onMouseover", "onMouseout", "class"]))
}