
import { defineComponent } from 'vue'


export default defineComponent({
    name: 'ShopMessage',

    props: {
        message: {
            type: String,
            required: true
        }
    }
})
