import { Router, RouteRecordRaw, createRouter, createWebHistory } from 'vue-router'

import CustomiserView from './views/CustomiserView.vue'
import ShopView from './views/ShopView.vue'


export default class Routes {
    private static readonly routes: RouteRecordRaw[] = [
        { name: 'home', path: '/', component: CustomiserView, props: true },
        { name: 'shop', path: '/shop', component: ShopView, props: true }
    ]

    public static get router(): Router {
        return createRouter({
            history: createWebHistory(),
            routes: Routes.routes
        })
    }
}
