import { createApp } from 'vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import { globalCookiesConfig } from 'vue3-cookies'

import App from './App.vue'
import Routes from './routes'
import Config from './config'

import 'animate.css'
import '@/assets/scss/global.scss'

globalCookiesConfig({
    expireTimes: "30d",
    path: "/",
    domain: "",
    secure: true,
    sameSite: "None",
});

Config.initialize()
loadFonts()

createApp(App)
    .use(vuetify)
    .use(Routes.router)
    .mount('#app')
