
import { defineComponent } from 'vue'

import ShopItem from '@/components/ShopItem.vue'
import GeneralShopItem from '@/components/GeneralShopItem.vue'
import GeneralShopPreview from '@/components/GeneralShopPreview.vue'

import Shop, { ShopInventory, ShopId } from '@/io/shop'
import itemData from '@/io/items'
import { DatabaseBase } from '@/io/database'
import Inventory, { UserInventory } from '@/io/inventory'


export default defineComponent({
    name: 'ShopPanel',

    components: {
        ShopItem,
        GeneralShopItem,
        GeneralShopPreview
    },

    props: {
        shopInventory: {
            type: ShopInventory,
            required: true
        },
        db: {
            type: DatabaseBase,
            required: true
        }
    },

    methods: {
        itemSelected: function(type: string, id: string) {
            this.selectedItem = new ShopId(id, type)
        },

        buyItem: function(type: string, id: string, price: number) {
            if ( Inventory.add(this.db, type, id, price) ) {
                this.$emit('saleMade')
                this.inventory = Inventory.publicInventory(this.db)
            }
            else
                this.$emit('noSaleMade')
            
            this.$forceUpdate()
        },

        itemIsOwned: function(type: string, id: string): boolean {
            return this.inventory[type as keyof UserInventory].includes(id)
        },

        emitSpecialsSelected: function() {
            if ( this.lastShop != 'specials' ) {
                this.$emit('specialsSelected')
                this.lastShop = 'specials'
            }
        },

        emitGeneralSelected: function() {
            if ( this.lastShop != 'general' ) {
                this.$emit('generalSelected')
                this.lastShop = 'general'
            }
        }
    },

    data() {
        return {
            lastShop: 'specials',
            shop: null,
            itemData: itemData,
            generalItems: Shop.generalShopItems,
            inventory: Inventory.publicInventory(this.db),

            selectedItem: new ShopId('0headphones', 'head'),

            sections: [
                ['Head', 'head'],
                ['Neck', 'neck'],
                ['Body', 'body'],
                ['Legs', 'legs'],
                ['Special', 'special'],
                ['Skins', 'skin'],
                ['Backgrounds', 'background']
            ]
        }
    }
})
