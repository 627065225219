
import { defineComponent, PropType } from 'vue'

import { Rarity } from '@/io/items'


export default defineComponent({
    name: 'ShopItem',

    props: {
        name: {
            type: String,
            required: true
        },
        rarity: {
            type: Number as PropType<Rarity>,
            required: true
        },
        desc: {
            type: String,
            required: true
        },
        type: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            required: true
        },
        price: {
            type: Number,
            required: true
        },
        sale: {
            type: Number,
            required: true
        },
        isOwned: {
            type: Boolean,
            required: true
        }
    },

    methods: {
        bought: function() {
            this.buying = true

            const typeCached = this.type
            const iconCached = this.icon
            const priceCached = this.finalPrice

            setTimeout(() => {
                this.buying = false
                this.$emit('item-bought', typeCached, iconCached, priceCached)
            }, 1000)
        }
    },

    data() {
        return {
            finalPrice: Math.ceil(this.price * (1.0 - this.sale / 100.0)),
            noSale: this.sale <= 0.0,
            rarityName: Rarity[this.rarity].toLowerCase(),
            buying: false,
        }
    }
})
