import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_ItemButton = _resolveComponent("ItemButton")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_v_window = _resolveComponent("v-window")!
  const _component_v_pagination = _resolveComponent("v-pagination")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    height: "60vh",
    class: "transparent-white"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_tabs, {
        id: "invTabs",
        grow: "",
        modelValue: _ctx.inventory,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inventory) = $event))
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.toolbarIcons, (item) => {
            return (_openBlock(), _createBlock(_component_v_tab, {
              key: item,
              value: item.type,
              onClick: _ctx.refreshNumberingAndPage
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_img, {
                  src: require(`@/assets/img/icons/${item.icon}.png`),
                  alt: item.type
                }, null, 8, ["src", "alt"])
              ]),
              _: 2
            }, 1032, ["value", "onClick"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_v_window, {
        id: "inventoryWindow",
        modelValue: _ctx.inventory,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.inventory) = $event))
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.toolbarIcons, (item) => {
            return (_openBlock(), _createBlock(_component_v_window_item, {
              class: "fill-height",
              key: item,
              value: item.type
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userInventory[item.type].slice(..._ctx.itemsToDisplay), (inv) => {
                  return (_openBlock(), _createBlock(_component_ItemButton, {
                    class: "inventory-button",
                    key: inv,
                    name: _ctx.itemData[item.type][inv].name,
                    rarity: _ctx.itemData[item.type][inv].rarity,
                    type: item.type,
                    icon: inv,
                    selected: _ctx.selectedItems[item.type] == inv,
                    onClick: ($event: any) => (_ctx.$emit('itemSelected', item.type, inv))
                  }, null, 8, ["name", "rarity", "type", "icon", "selected", "onClick"]))
                }), 128))
              ]),
              _: 2
            }, 1032, ["value"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_v_pagination, {
        id: "inventoryPagination",
        modelValue: _ctx.page,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.page) = $event)),
        length: _ctx.numberOfPages,
        rounded: "circle",
        onClick: _ctx.refreshNumbering
      }, null, 8, ["modelValue", "length", "onClick"])
    ]),
    _: 1
  }))
}