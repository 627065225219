
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'DiscordProfile',
    props: {
        username: {
            type: String,
            default: 'Undefined'
            
        },
        discriminator: {
            type: String,
            default: '0000'
        },
        avatarUrl: {
            type: String,
            default: 'require("@/assets/img/test_profile.png")'
        }
    }
})
