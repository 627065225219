
import { defineComponent } from 'vue'

import InventoryPanel from '@/components/InventoryPanel.vue'
import InventoryPreview from '@/components/InventoryPreview.vue'

import Inventory, { SelectedItems } from '@/io/inventory'
import { DatabaseBase } from '@/io/database'


export default defineComponent({
    name: 'CustomiserView',

    components: {
        InventoryPanel,
        InventoryPreview
    },

    props: {
        db: {
            type: DatabaseBase,
            required: true
        }
    },

    methods: {
        itemSelected: function(type: string, value: string) {
            this.selectedItems[type as keyof SelectedItems] = value
            Inventory.set(this.db, this.selectedItems)
        }
    },

    data() {
        return {
            userInventory: Inventory.publicInventory(this.db),
            selectedItems: Inventory.publicSelected(this.db)
        }
    }
})
