import Config from '@/config'
import { isNullOrEmpty } from '@/utility/string-utility'
import { DiscordCredentials } from './database'


const DISCORD_TOKEN_URL = 'https://discord.com/api/oauth2/token'
const DISCORD_USER_URL = 'https://discord.com/api/users/@me'

export class DiscordError extends Error {
    constructor(responseCode: number) {
        super(`Failed to connect to Discord with response code: ${responseCode}`)
        Object.setPrototypeOf(this, DiscordError.prototype);
    }
}

export class DiscordAuth {
    constructor(
        public accessToken: string,
        public tokenType: string,
        public profile: DiscordProfileData = new DiscordProfileData()
    ) {}
}

export class DiscordProfileData {
    private _valid: boolean

    public get valid(): boolean {
        return this._valid
    }

    public readonly avatarUrl: string

    constructor(
        public userId: string = '',
        public username: string = '',
        public discriminator: string = '',
        public avatar: string = ''
    ) {
        this._valid = !isNullOrEmpty(userId)
        this.avatarUrl = `https://cdn.discordapp.com/avatars/${this.userId}/${this.avatar}.png?size=256`
    }
}

export default class Discord {

    public static requestAuthorization() {
        window.location.href = Config.discordAuthorizeUrl
    }

    public static async authorize(code: string, discordCredentials: DiscordCredentials): Promise<DiscordAuth> {
        const res = await fetch(DISCORD_TOKEN_URL, {
            method: 'POST',
            body: new URLSearchParams({
                client_id: Config.clientId ?? discordCredentials.clientId,
                client_secret: Config.clientSecret ?? discordCredentials.clientSecret,
                code,
                grant_type: 'authorization_code',
                redirect_uri: Config.discordRedirectUri,
                scope: 'identify',
            }),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
        
    
        if ( !res.ok ) {
            throw new DiscordError(res.status)
        }
    
        const data = await res.json()
        return new DiscordAuth(data.access_token, data.token_type)
    }
    
    public static async initialize(auth: DiscordAuth): Promise<DiscordAuth> {
        return await fetch(DISCORD_USER_URL, {
            headers: {
                authorization: `${auth.tokenType} ${auth.accessToken}`
            }
        })
        .then(result => {
            if ( result.status != 200 )
                throw new DiscordError(result.status)

            return result.json()
        })
        .then(response => {
            const { id, username, discriminator, avatar } = response
            const profile = new DiscordProfileData(id, username, discriminator, avatar)
            return new DiscordAuth(auth.accessToken, auth.tokenType, profile)
        })
    }
}
