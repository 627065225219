
import { defineComponent, PropType } from 'vue'

import { Rarity } from '@/io/items'


export default defineComponent({
    name: 'ItemButton',

    props: {
        name: {
            type: String,
            required: true
        },
        rarity: {
            type: Number as PropType<Rarity>,
            required: true
        },
        type: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            required: true
        },
        selected: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        onMouseOver: function() {
            this.hovering = true
        },
        onMouseOut: function() {
            this.hovering = false
        }
    },

    data() {
        return {
            hovering: false,
            rarityName: Rarity[this.rarity].toLowerCase()
        }
    }
})
