
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'GeneralShopItem',

    props: {
        type: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            required: true
        },
        price: {
            type: Number,
            required: true
        },
        selected: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        onMouseOver: function() {
            this.hovering = true
        },
        onMouseOut: function() {
            this.hovering = false
        }
    },

    data() {
        return {
            hovering: false
        }
    }
})
