export class ConfigError extends Error {
    constructor(constantName: string) {
        super(`Environment variable '${constantName}' was not found.`)
        Object.setPrototypeOf(this, ConfigError.prototype);
    }
}

export class DatabaseConfig {
    private _type: string = ''
    private _debugId: string = ''

    private _firebaseApiKey: string = ''
    private _firebaseAuthDomain: string = ''
    private _firebaseProjectId: string = ''
    private _firebaseStorageBucket: string = ''
    private _firebaseMessagingSenderId: string = ''
    private _firebaseAppId: string = ''
    private _firebaseMeasurementId: string = ''

    public get type(): string {
        return this._type
    }

    public get debugId(): string {
        return this._debugId
    }

    public get firebaseApiKey(): string {
        return this._firebaseApiKey
    }

    public get firebaseAuthDomain(): string {
        return this._firebaseAuthDomain
    }

    public get firebaseProjectId(): string {
        return this._firebaseProjectId
    }

    public get firebaseStorageBucket(): string {
        return this._firebaseStorageBucket
    }

    public get firebaseMessagingSenderId(): string {
        return this._firebaseMessagingSenderId
    }

    public get firebaseAppId(): string {
        return this._firebaseAppId
    }

    public get firebaseMeasurementId(): string {
        return this._firebaseMeasurementId
    }

    constructor() {
        this._type = process.env.VUE_APP_DB_TYPE ?? ''
        this._debugId = process.env.VUE_APP_DB_DEBUG_ID ?? ''

        if ( !this._type )
            throw new ConfigError('VUE_APP_DB_TYPE')

        if ( this._type == 'firebase' ) {
            this._firebaseApiKey = process.env.VUE_APP_DB_FIREBASE_API_KEY ?? ''
            this._firebaseAuthDomain = process.env.VUE_APP_DB_FIREBASE_AUTH_DOMAIN ?? ''
            this._firebaseProjectId = process.env.VUE_APP_DB_FIREBASE_PROJECT_ID ?? ''
            this._firebaseStorageBucket = process.env.VUE_APP_DB_FIREBASE_STORAGE_BUCKET ?? ''
            this._firebaseMessagingSenderId = process.env.VUE_APP_DB_FIREBASE_MESSAGING_SENDER_ID ?? ''
            this._firebaseAppId = process.env.VUE_APP_DB_FIREBASE_APP_ID ?? ''
            this._firebaseMeasurementId = process.env.VUE_APP_DB_FIREBASE_MEASUREMENT_ID ?? ''

            if ( !this._firebaseApiKey )
                throw new ConfigError('VUE_APP_DB_FIREBASE_API_KEY')
            
            if ( !this._firebaseAuthDomain )
                throw new ConfigError('VUE_APP_DB_FIREBASE_AUTH_DOMAIN')
            
            if ( !this._firebaseProjectId )
                throw new ConfigError('VUE_APP_DB_FIREBASE_PROJECT_ID')
            
            if ( !this._firebaseStorageBucket )
                throw new ConfigError('VUE_APP_DB_FIREBASE_STORAGE_BUCKET')
            
            if ( !this._firebaseMessagingSenderId )
                throw new ConfigError('VUE_APP_DB_FIREBASE_MESSAGING_SENDER_ID')
            
            if ( !this._firebaseAppId )
                throw new ConfigError('VUE_APP_DB_FIREBASE_APP_ID')
            
            if ( !this._firebaseMeasurementId )
                throw new ConfigError('VUE_APP_DB_FIREBASE_MEASUREMENT_ID')
        }
        else
            throw new Error('Other database options are currently unavailable.')
    }
}

export default class Config {
    private static readonly _initializeMessage: string = 'Config has not been initialized.'
    private static _initialized : boolean = false;

    private static _clientId: string | null = ''
    private static _clientSecret: string | null = ''
    private static _discordAuthorizeUrl: string = ''
    private static _discordRedirectUri: string = ''
    private static _db: DatabaseConfig

    public static get clientId(): string | null {
        if ( !this._initialized )
            throw Error(this._initializeMessage)

        return this._clientId
    }

    public static get clientSecret(): string | null {
        if ( !this._initialized )
            throw Error(this._initializeMessage)

        return this._clientSecret
    }

    public static get discordAuthorizeUrl(): string {
        if ( !this._initialized )
            throw Error(this._initializeMessage)

        return this._discordAuthorizeUrl
    }

    public static get discordRedirectUri(): string {
        if ( !this._initialized )
            throw Error(this._initializeMessage)

        return this._discordRedirectUri
    }

    public static get db(): DatabaseConfig {
        if ( !this._initialized )
            throw Error(this._initializeMessage)

        return this._db
    }

    public static initialize() {
        this._initialized = true

        this._clientId = process.env.VUE_APP_CLIENT_ID ?? null
        this._clientSecret = process.env.VUE_APP_CLIENT_SECRET ?? null
        this._discordAuthorizeUrl = process.env.VUE_APP_DISCORD_AUTHORIZE_URL ?? ''
        this._discordRedirectUri = process.env.VUE_APP_DISCORD_REDIRECT_URI ?? ''

        if ( !this.discordAuthorizeUrl )
            throw new ConfigError('VUE_APP_DISCORD_AUTHORIZE_URL')
        
        if ( !this.discordRedirectUri )
            throw new ConfigError('VUE_APP_DISCORD_REDIRECT_URI')

        this._db = new DatabaseConfig()
    }
}
