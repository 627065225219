
import { defineComponent } from 'vue'

import ShopPanel from '@/components/ShopPanel.vue'
import ShopMessage from '@/components/ShopMessage.vue'

import Shop from '@/io/shop'
import { DatabaseBase } from '@/io/database'


export default defineComponent({
    name: 'ShopView',

    components: {
        ShopPanel,
        ShopMessage
    },

    props: {
        db: {
            type: DatabaseBase,
            required: true
        }
    },

    methods: {
        updateMessageWithGreeting: function() {
            this.message = Shop.randomGreetingMessage
        },
        updateMessageWithGeneral: function() {
            this.message = Shop.randomGeneralMessage
        },
        updateMessageWithSale: function() {
            this.message = Shop.randomSaleMessage
        },
        updateMessageWithNoSale: function() {
            this.message = Shop.randomNoSaleMessage
        }
    },

    data() {
        return {
            shopInventory: Shop.publicShopInventory(this.db),
            message: Shop.randomGreetingMessage
        }
    }
})
