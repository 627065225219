
import { defineComponent } from 'vue'

import itemData, { getHeadBack, getShadow } from '@/io/items'


export default defineComponent({
    name: "InventoryPreview",

    props: {
        head: {
            type: String,
            default: 'none'
        },
        neck: {
            type: String,
            default: 'none'
        },
        body: {
            type: String,
            default: 'none'
        },
        legs: {
            type: String,
            default: 'none'
        },
        special: {
            type: String,
            default: 'none'
        },
        skin: {
            type: String,
            default: '0brown'
        },
        background: {
            type: String,
            default: 'none'
        }
    },

    watch: {
        head: function() {
            this.headBack = getHeadBack(this.head)
        },
        skin: function() {
            this.shadow = getShadow(this.skin)
        }
    },

    methods: {
        windowResized: function() {
            setTimeout(() => {
                const h = window?.innerHeight ?? 1200
                this.scale = h < 1200 ? 0.7 : 1.0
            }, 100)
        },
        calculateX: function(type: string, value: string) : string {
            const offset = this.scale < 1.0 ? 15.0 : 0.0
            return (itemData[type][value].x * this.scale + offset) + 'px'
        },
        calculateY: function(type: string, value: string) : string {
            const offset = this.scale < 1.0 ? 10.0 : 0.0
            return (itemData[type][value].y * this.scale + offset) + 'px'
        },
        calculateWidth: function(type: string, value: string) : number {
            return itemData[type][value].w * this.scale * 0.57
        },

        calculateHeight: function(type: string, value: string) : number {
            return itemData[type][value].h * this.scale * 0.57
        }
    },

    mounted() {
        window.addEventListener("resize", this.windowResized);
    },

    unmounted() {
        window.removeEventListener("resize", this.windowResized);
    },

    data() {
        return {
            scale: window.innerHeight < 1200 ? 0.7 : 1.0,
            shadow: getShadow(this.skin),
            headBack: getHeadBack(this.head)
        }
    }
})
