import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fill-height ma-2" }
const _hoisted_2 = {
  id: "previewHolder",
  class: "fill-height"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_v_img, {
        id: "previewBackground",
        src: require(`../assets/img/custom/background/${_ctx.background}.png`)
      }, null, 8, ["src"]),
      _createVNode(_component_v_img, {
        id: "previewShadow",
        src: require(`../assets/img/shadows/${_ctx.shadow}.png`)
      }, null, 8, ["src"]),
      _createVNode(_component_v_img, {
        class: "preview",
        src: require(`../assets/img/custom/headback/${_ctx.headBack}.png`),
        width: _ctx.calculateWidth('headback', _ctx.headBack),
        height: _ctx.calculateHeight('headback', _ctx.headBack),
        style: _normalizeStyle({ 
                    left: _ctx.calculateX('headback', _ctx.headBack),
                    bottom: _ctx.calculateY('headback', _ctx.headBack)})
      }, null, 8, ["src", "width", "height", "style"]),
      _createVNode(_component_v_img, {
        class: "preview",
        src: require(`../assets/img/custom/skin/${_ctx.skin}.png`),
        width: _ctx.calculateWidth('skin', _ctx.skin),
        height: _ctx.calculateHeight('skin', _ctx.skin),
        style: _normalizeStyle({ 
                    left: _ctx.calculateX('skin', _ctx.skin),
                    bottom: _ctx.calculateY('skin', _ctx.skin)})
      }, null, 8, ["src", "width", "height", "style"]),
      _createVNode(_component_v_img, {
        class: "preview",
        src: require(`../assets/img/custom/legs/${_ctx.legs}.png`),
        width: _ctx.calculateWidth('legs', _ctx.legs),
        height: _ctx.calculateHeight('legs', _ctx.legs),
        style: _normalizeStyle({ 
                    left: _ctx.calculateX('legs', _ctx.legs),
                    bottom: _ctx.calculateY('legs', _ctx.legs)})
      }, null, 8, ["src", "width", "height", "style"]),
      _createVNode(_component_v_img, {
        class: "preview",
        src: require(`../assets/img/custom/body/${_ctx.body}.png`),
        width: _ctx.calculateWidth('body', _ctx.body),
        height: _ctx.calculateHeight('body', _ctx.body),
        style: _normalizeStyle({ 
                    left: _ctx.calculateX('body', _ctx.body),
                    bottom: _ctx.calculateY('body', _ctx.body)})
      }, null, 8, ["src", "width", "height", "style"]),
      _createVNode(_component_v_img, {
        class: "preview",
        src: require(`../assets/img/custom/neck/${_ctx.neck}.png`),
        width: _ctx.calculateWidth('neck', _ctx.neck),
        height: _ctx.calculateHeight('neck', _ctx.neck),
        style: _normalizeStyle({ 
                    left: _ctx.calculateX('neck', _ctx.neck),
                    bottom: _ctx.calculateY('neck', _ctx.neck)})
      }, null, 8, ["src", "width", "height", "style"]),
      _createVNode(_component_v_img, {
        class: "preview",
        src: require(`../assets/img/custom/head/${_ctx.head}.png`),
        width: _ctx.calculateWidth('head', _ctx.head),
        height: _ctx.calculateHeight('head', _ctx.head),
        style: _normalizeStyle({ 
                    left: _ctx.calculateX('head', _ctx.head),
                    bottom: _ctx.calculateY('head', _ctx.head)})
      }, null, 8, ["src", "width", "height", "style"]),
      _createVNode(_component_v_img, {
        class: "preview",
        src: require(`../assets/img/custom/special/${_ctx.special}.png`),
        width: _ctx.calculateWidth('special', _ctx.special),
        height: _ctx.calculateHeight('special', _ctx.special),
        style: _normalizeStyle({ 
                    left: _ctx.calculateX('special', _ctx.special),
                    bottom: _ctx.calculateY('special', _ctx.special)})
      }, null, 8, ["src", "width", "height", "style"])
    ])
  ]))
}