import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InventoryPanel = _resolveComponent("InventoryPanel")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_InventoryPreview = _resolveComponent("InventoryPreview")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "pa-0" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { "no-gutters": "" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            lg: "12",
            xl: "6"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_InventoryPanel, {
                selectedItems: _ctx.selectedItems,
                userInventory: _ctx.userInventory,
                onItemSelected: _ctx.itemSelected
              }, null, 8, ["selectedItems", "userInventory", "onItemSelected"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "12",
            xl: "6"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_InventoryPreview, _mergeProps({ id: "inventoryPreview" }, _ctx.selectedItems), null, 16)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}