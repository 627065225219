import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_card_header = _resolveComponent("v-card-header")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    id: "profileContainer",
    class: "transparent-white"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_container, { "fill-height": "" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, { class: "d-flex align-center" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { cols: "3" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_avatar, { size: "64" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_img, { src: _ctx.avatarUrl }, null, 8, ["src"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, { cols: "9" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_header, { class: "py-0" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.username), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card_subtitle, null, {
                    default: _withCtx(() => [
                      _createTextVNode(" #" + _toDisplayString(_ctx.discriminator), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}