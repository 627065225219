
import { defineComponent } from 'vue'
import { useCookies } from 'vue3-cookies'

import MainInterface from '@/components/MainInterface.vue'
import TitleSplash from '@/components/TitleSplash.vue'

import Discord, { DiscordAuth, DiscordProfileData } from '@/io/discord'
import DatabaseManager, { DatabaseBase, FirebaseDatabase } from '@/io/database'
import Config from './config'

export default defineComponent({
    name: "App",

    components: {
        MainInterface,
        TitleSplash
    },

    setup() {
        const { cookies } = useCookies()
        return { cookies }
    },

    created() {
        const debug = new URL(location.href).searchParams.get('debug')

        const discordAccessToken = this.cookies.get('discord_access_token')
        const discordTokenType = this.cookies.get('discord_token_type')

        if ( discordAccessToken && discordTokenType ) {
            const auth = new DiscordAuth(discordAccessToken, discordTokenType)
            FirebaseDatabase.connect()
                .then(() => {
                    Discord.initialize(auth)
                    .then((response) => {
                        this.profile = response.profile
                        return Config.db.debugId ? Config.db.debugId : this.profile.userId
                    })
                    .catch(() => {
                        this.cookies.set('discord_access_token', '')
                        this.cookies.set('discord_token_type', '')
                        
                        if ( debug == null )
                            Discord.requestAuthorization()

                        return Config.db.debugId
                    })
                    .then(DatabaseManager.initialize)
                    .then(result => {
                        this.db = result
                        this.ready = true
                    })
                    .catch(error => {
                        this.cookies.set('discord_access_token', '')
                        this.cookies.set('discord_token_type', '')

                        console.log(error)
                    })
                })

            return
        }

        const code = new URL(location.href).searchParams.get('code')

        if ( code == null ) {
            if ( debug == null )
                Discord.requestAuthorization()

            return
        }

        FirebaseDatabase.connect()
            .then((discordCredentials) => {
                Discord.authorize(code, discordCredentials)
                .then(Discord.initialize)
                .then(auth => {
                    this.cookies.set('discord_access_token', auth.accessToken)
                    this.cookies.set('discord_token_type', auth.tokenType)
                    this.profile = auth.profile

                    return Config.db.debugId ? Config.db.debugId : this.profile.userId
                })
                .catch(() => {
                    if ( debug == null )
                        Discord.requestAuthorization()
                    return Config.db.debugId
                })
                .then(DatabaseManager.initialize)
                .then(result => {
                    this.db = result
                    this.ready = true
                })
                .catch(error => {
                    console.log(error)
                })
            })
    },

    data() {
        return {
            profile: new DiscordProfileData(),
            db: new DatabaseBase(),
            ready: false
        }
    }
})
