import { DatabaseBase } from "./database"

export class UserInventory {
    constructor(
        public head: string[] = [],
        public neck: string[] = [],
        public body: string[] = [],
        public legs: string[] = [],
        public special: string[] = [],
        public skin: string[] = [],
        public background: string[] = []
    ) {}
}

export class SelectedItems {
    constructor(
        public head: string = 'none',
        public neck: string = 'none',
        public body: string = 'none',
        public legs: string = 'none',
        public special: string = 'none',
        public skin: string = '0brown',
        public background: string = 'none'
    ) {}
}

export default class Inventory {
    public static publicInventory(db: DatabaseBase): UserInventory {
        return new UserInventory(
            db.invHead,
            db.invNeck,
            db.invBody,
            db.invLegs,
            db.invSpecial,
            db.invSkin,
            db.invBackground
        )
    }

    public static publicSelected(db: DatabaseBase): SelectedItems {
        return new SelectedItems(...db.selected)
    }

    public static set(db: DatabaseBase, value: SelectedItems) {
        const arr = [value.head, value.neck, value.body, value.legs,
                     value.special, value.skin, value.background]
        db.selected = arr
    }

    private static insertIntoInventory(inventory: string[], value: string): string[] {
        for ( let i = 0; i < inventory.length; ++i ) {
            const compare = value.localeCompare(inventory[i])

            if ( compare >= 0 ) {
                inventory.splice(i, 0, value)
                return inventory
            }
        }

        return [...inventory, value]
    }

    public static add(db: DatabaseBase, type: string, value: string, price: number): boolean {
        if ( db.tickets < price )
            return false
        
        db.tickets -= price

        switch ( type ) {
            case 'head':
                db.invHead = this.insertIntoInventory(db.invHead, value)
                break
            case 'neck':
                db.invNeck = this.insertIntoInventory(db.invNeck, value)
                break
            case 'body':
                db.invBody = this.insertIntoInventory(db.invBody, value)
                break
            case 'legs':
                db.invLegs = this.insertIntoInventory(db.invLegs, value)
                break
            case 'special':
                db.invSpecial = this.insertIntoInventory(db.invSpecial, value)
                break
            case 'skin':
                db.invSkin = this.insertIntoInventory(db.invSkin, value)
                break
            case 'background':
                db.invBackground = this.insertIntoInventory(db.invBackground, value)
                break
            default:
                throw new Error(`Attempted to add an inventory item of a type that does not exist: ${type}`)
        }

        return true
    }
}
