
import { defineComponent } from 'vue'

import ItemButton from '@/components/ItemButton.vue'

import { UserInventory, SelectedItems } from '@/io/inventory'
import itemData from '@/io/items'


export default defineComponent({
    name: "MainInterface",

    components: {
        ItemButton
    },

    props: {
        selectedItems: {
            type: SelectedItems,
            required: true
        },
        userInventory: {
            type: UserInventory,
            required: true
        }
    },

    methods: {
        windowResized: function() {
            setTimeout(() => {
                this.refreshNumbering()
            }, 100)
        },
        refreshNumbering: function() {
            this.numberOfPages = this.getNumberOfPages(this.inventory, this.userInventory)
            this.itemsToDisplay = this.getInventorySlice(this.page, this.inventory, this.userInventory)
        },
        refreshNumberingAndPage: function() {
            this.page = 1
            this.refreshNumbering()
        },
        getNumberOfItemsPerPage: function() : number {
            const windowElement = document.getElementById('inventoryWindow')
            
            if ( windowElement == null )
                return 0

            const width = windowElement.offsetWidth
            const height = windowElement.offsetHeight
            const itemDimX = 110
            const itemDimY = 115

            const x = Math.floor((width - 10) / itemDimX)
            const y = Math.floor((height - 10) / itemDimY)
            const total = x * y

            return total
        },
        getNumberOfItemsPerPageAndNumberOfPages: function(pageId: string, inventory: UserInventory) : [number, number] {
            const numberOfItemsPerPage = this.getNumberOfItemsPerPage()

            if ( numberOfItemsPerPage <= 0 )
                return [0, 0]

            const numberOfItems = inventory[pageId as keyof UserInventory].length
            return [numberOfItemsPerPage, Math.floor((numberOfItems - 0.5) / numberOfItemsPerPage) + 1]
        },
        getNumberOfPages: function(pageId: string, inventory: UserInventory) : number {
            return this.getNumberOfItemsPerPageAndNumberOfPages(pageId, inventory)[1]
        },
        getInventorySlice: function(currentPage:number, pageId: string, inventory: UserInventory) : [number, number] {
            const numberOfItemsPerPage = this.getNumberOfItemsPerPage()

            const numberOfItems = inventory[pageId as keyof UserInventory].length
            const startIndex = (currentPage - 1) * numberOfItemsPerPage
            const endIndex = Math.min(startIndex + numberOfItemsPerPage, numberOfItems)

            return [startIndex, endIndex]
        }
    },

    mounted() {
        this.refreshNumbering()
        window.addEventListener("resize", this.windowResized);
    },

    unmounted() {
        window.removeEventListener("resize", this.windowResized);
    },

    data() {
        return {
            inventory: 'head',
            page: 1,
            itemData: itemData,
            numberOfPages:  0,
            itemsToDisplay: [0, 0],
            toolbarIcons: [
                {
                    icon: 'icon_head',
                    type: 'head'
                },
                {
                    icon: 'icon_neck',
                    type: 'neck'
                },
                {
                    icon: 'icon_body',
                    type: 'body'
                },
                {
                    icon: 'icon_legs',
                    type: 'legs'
                },
                {
                    icon: 'icon_special',
                    type: 'special'
                },
                {
                    icon: 'icon_skin',
                    type: 'skin'
                },
                {
                    icon: 'icon_background',
                    type: 'background'
                }
            ]
        }
    }
});
