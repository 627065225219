import { FirebaseOptions, initializeApp, FirebaseApp } from 'firebase/app'
import { getAuth, signInAnonymously } from 'firebase/auth'
import { Firestore, getFirestore, getDoc, setDoc, updateDoc, doc, WithFieldValue, DocumentData } from 'firebase/firestore/lite'
import { Analytics, getAnalytics } from 'firebase/analytics'

import Config from '@/config'
import { getRandomRareItem } from './items'


export class DBError extends Error {
    constructor(message: string) {
        super(`A database error occurred: ${message}`)
        Object.setPrototypeOf(this, DBError.prototype);
    }
}

export class DBNoEntryError extends Error {
    constructor() {
        super('')
        Object.setPrototypeOf(this, DBNoEntryError.prototype);
    }
}


export class DatabaseUserData {
    constructor(
        public tickets: number = 100,
        public selected: string[] = ['none', 'none', 'none', 'none', 'none', '0brown', 'none'],
        public invHead: string[] = ['none'],
        public invNeck: string[] = ['none'],
        public invBody: string[] = ['none'],
        public invLegs: string[] = ['none'],
        public invSpecial: string[] = ['none'],
        public invSkin: string[] = ['0brown'],
        public invBackground: string[] = ['none']
    ){}
}

export class DatabaseShopData {
    constructor(
        public items: string[] = [],
        public types: string[] = [],
        public sales: number[] = []
    ){}
}

export class DiscordCredentials {
    constructor(
        public clientId: string = '',
        public clientSecret: string = ''
    ){}
}

export class DatabaseBase {
    private _userId: string = ''
    private _userData: DatabaseUserData = new DatabaseUserData()
    private _shopData: DatabaseShopData = new DatabaseShopData()

    public async initialize(userId: string) {
        this._userId = userId;

        if ( !this._userId ) {
            console.log('Invalid User ID. Failed to establish connection to database')
            return
        }

        return this.load(this._userId)
            .then(([user, shop]) => {
                this._userData = user
                this._shopData = shop
            })
    }

    protected async load(userId: string) : Promise<[DatabaseUserData, DatabaseShopData]> {
        return [new DatabaseUserData(), new DatabaseShopData()]
    }

    protected setTickets(userId: string, value: number) {}
    protected setSelected(userId: string, value: string[]) {}
    protected setInvHead(userId: string, value: string[]) {}
    protected setInvNeck(userId: string, value: string[]) {}
    protected setInvBody(userId: string, value: string[]) {}
    protected setInvLegs(userId: string, value: string[]) {}
    protected setInvSpecial(userId: string, value: string[]) {}
    protected setInvSkin(userId: string, value: string[]) {}
    protected setInvBackground(userId: string, value: string[]) {}
    protected setShopItems(items: string[], types: string[], sales: number[]) {}

    public get tickets() : number {
        return this._userData.tickets
    }

    public set tickets(value: number) {
        this.setTickets(this._userId, value)
        this._userData.tickets = value
    }

    public get selected() : string[] {
        return this._userData.selected
    }

    public set selected(value: string[]) {
        this.setSelected(this._userId, value)
        this._userData.selected = value
    }

    public get invHead() : string[] {
        return this._userData.invHead
    }

    public set invHead(value: string[]) {
        this.setInvHead(this._userId, value)
        this._userData.invHead = value
    }

    public get invNeck() : string[] {
        return this._userData.invNeck
    }

    public set invNeck(value: string[]) {
        this.setInvNeck(this._userId, value)
        this._userData.invNeck = value
    }

    public get invBody() : string[] {
        return this._userData.invBody
    }

    public set invBody(value: string[]) {
        this.setInvBody(this._userId, value)
        this._userData.invBody = value
    }

    public get invLegs() : string[] {
        return this._userData.invLegs
    }

    public set invLegs(value: string[]) {
        this.setInvLegs(this._userId, value)
        this._userData.invLegs = value
    }

    public get invSpecial() : string[] {
        return this._userData.invSpecial
    }

    public set invSpecial(value: string[]) {
        this.setInvSpecial(this._userId, value)
        this._userData.invSpecial = value
    }

    public get invSkin() : string[] {
        return this._userData.invSkin
    }

    public set invSkin(value: string[]) {
        this.setInvSkin(this._userId, value)
        this._userData.invSkin = value
    }

    public get invBackground() : string[] {
        return this._userData.invBackground
    }

    public set invBackground(value: string[]) {
        this.setInvBackground(this._userId, value)
        this._userData.invBackground = value
    }

    public get shopItems() : DatabaseShopData {
        return this._shopData
    }

    public setShopitems(items: string[], types: string[], sales: number[]) {
        this.setShopItems(items, types, sales)
        this._shopData = new DatabaseShopData(items, types, sales)
    }
}

export class FirebaseDatabase extends DatabaseBase {
    private static _app: FirebaseApp | null = null
    private static _store: Firestore | null = null
    private static _analytics: Analytics | null = null

    protected setTickets(userId: string, value: number) {
        if ( FirebaseDatabase._store == null )
            return

        updateDoc(doc(FirebaseDatabase._store, 'users', userId), {
            tickets: value
        })
    }

    private setArr(userId: string, content: any) {
        if ( FirebaseDatabase._store == null )
            return

        updateDoc(doc(FirebaseDatabase._store, 'users', userId), content)
    }

    protected setSelected(userId: string, value: string[]) {
        const v = value.join(',')
        this.setArr(userId, { dirty: true, selected: v })
    }

    protected setInvHead(userId: string, value: string[]) {
        const v = value.join(',')
        this.setArr(userId, { inv_head: v })
    }

    protected setInvNeck(userId: string, value: string[]) {
        const v = value.join(',')
        this.setArr(userId, { inv_neck: v })
    }

    protected setInvBody(userId: string, value: string[]) {
        const v = value.join(',')
        this.setArr(userId, { inv_body: v })
    }

    protected setInvLegs(userId: string, value: string[]) {
        const v = value.join(',')
        this.setArr(userId, { inv_legs: v })
    }

    protected setInvSpecial(userId: string, value: string[]) {
        const v = value.join(',')
        this.setArr(userId, { inv_special: v })
    }

    protected setInvSkin(userId: string, value: string[]) {
        const v = value.join(',')
        this.setArr(userId, { inv_skin: v })
    }

    protected setInvBackground(userId: string, value: string[]) {
        const v = value.join(',')
        this.setArr(userId, { inv_background: v })
    }

    protected setShopItems(items: string[], types: string[], sales: number[]) {
        if ( FirebaseDatabase._store == null )
            return

        const i = items.join(',')
        const t = types.join(',')
        const s = sales.join(',')
        updateDoc(doc(FirebaseDatabase._store, 'shop_items', 'special'), {
            items: i,
            types: t,
            sales: s
        })
    }

    public static discordCredentials(): Promise<DiscordCredentials> {
        if ( this._store == null )
            throw new DBError('Failed to load Discord credentials.')

        const result = getDoc(doc(this._store, 'keys', 'discord'))
            .then(snap => {
                if ( !snap.exists() )
                    throw new DBNoEntryError()
                
                const data = snap.data()
                return new DiscordCredentials(data.client_id, data.client_secret)
            })
        
        return result;
    }

    public static async connect(): Promise<DiscordCredentials> {
        const config: FirebaseOptions = {
            apiKey: Config.db.firebaseApiKey,
            authDomain: Config.db.firebaseAuthDomain,
            projectId: Config.db.firebaseProjectId,
            storageBucket: Config.db.firebaseStorageBucket,
            messagingSenderId: Config.db.firebaseMessagingSenderId,
            appId: Config.db.firebaseAppId,
            measurementId: Config.db.firebaseMeasurementId
        }

        this._app = initializeApp(config)
        this._store = getFirestore(this._app)
        this._analytics = getAnalytics(this._app)

        const auth = getAuth();
        await signInAnonymously(auth)

        return this.discordCredentials()
    }

    protected async load(userId: string): Promise<[DatabaseUserData, DatabaseShopData]> {
        if ( FirebaseDatabase._store == null )
            throw new DBError('Failed to load user data.')

        const userPromise = getDoc(doc(FirebaseDatabase._store, 'users', userId))
            .then(snap => {
                if ( !snap.exists() )
                    throw new DBNoEntryError()
                
                const data = snap.data()
                return new DatabaseUserData(
                    data.tickets,
                    data.selected.split(','),
                    data.inv_head.split(','),
                    data.inv_neck.split(','),
                    data.inv_body.split(','),
                    data.inv_legs.split(','),
                    data.inv_special.split(','),
                    data.inv_skin.split(','),
                    data.inv_background.split(',')
                )
            })
            .catch(error => {
                if ( error instanceof DBNoEntryError ) {
                    if ( FirebaseDatabase._store == null )
                        return new DatabaseUserData()
                    
                    const userData = {
                        dirty: true,
                        tickets: 100,
                        selected: 'none,none,none,none,none,0brown,none',
                        inv_head: 'none',
                        inv_neck: 'none',
                        inv_body: 'none',
                        inv_legs: 'none',
                        inv_special: 'none',
                        inv_skin: '0brown',
                        inv_background: 'none'
                    }

                    const databaseUserData = new DatabaseUserData()
                    
                    // Give the user a random rare item
                    const [category, item] = getRandomRareItem()

                    switch ( category ) {
                        case 'head':
                            userData.inv_head += `,${item}`
                            databaseUserData.invHead.push(item)
                            break;
                        case 'neck':
                            userData.inv_neck += `,${item}`
                            databaseUserData.invNeck.push(item)
                            break;
                        case 'body':
                            userData.inv_body += `,${item}`
                            databaseUserData.invBody.push(item)
                            break;
                        case 'legs':
                            userData.inv_legs += `,${item}`
                            databaseUserData.invLegs.push(item)
                            break;
                        case 'special':
                            userData.inv_special += `,${item}`
                            databaseUserData.invSpecial.push(item)
                            break;
                        case 'skin':
                            userData.inv_skin += `${item},0brown`
                            databaseUserData.invSkin.unshift(item)
                            break;
                        case 'background':
                            userData.inv_background += `,${item}`
                            databaseUserData.invBackground.push(item)
                            break;
                    }

                    setDoc(doc(FirebaseDatabase._store, 'users', userId), userData)
                    return databaseUserData
                }
                
                throw error
            })
        
        const shopPromise = getDoc(doc(FirebaseDatabase._store, 'shop_items', 'special'))
            .then(snap => {
                if ( !snap.exists() )
                    throw new DBError(`Failed to find shop data.`)
                
                const data = snap.data()
                return new DatabaseShopData(
                    data.items.split(','),
                    data.types.split(','),
                    data.sales.split(',').map((x: string) => parseInt(x, 10))
                )
            })
        
        return Promise.all([userPromise, shopPromise])
    }
}

export default class DatabaseManager {
    public static async initialize(userId: string) : Promise<DatabaseBase> {
        if ( Config.db.type == 'firebase' ) {
            const database = new FirebaseDatabase()
            return database.initialize(userId)
                .then(() => {
                    return database
                })
        }
        else {
            throw new Error(`Unknown database type specified: ${Config.db.type}`)
        }
    }
}