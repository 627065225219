export enum Rarity {
    Common,
    Uncommon,
    Rare,
    Epic,
    Legendary
}

export class ItemData {
    constructor(
        public name: string,
        public rarity: Rarity = Rarity.Common,
        public desc: string = '',
        public price: number = 0,
        public x: number = 0,
        public y: number = 0,
        public w: number = 0,
        public h: number = 0
    ){}

    public static box(x: number = 0, y: number = 0, w: number = 0, h: number = 0): ItemData {
        return new ItemData(
            '', undefined, undefined, undefined,
            x, y, w, h
        )
    }
}


const itemData: { [type: string]: { [value: string]: ItemData } } = {
    'head': {
        'none': new ItemData('None'),
        '0bandana': new ItemData('Bandana', Rarity.Common, 'Keeps the dirt out of the horse\'s mouth.', 15, 623, 403, 272, 348),
        '0headphones': new ItemData('Headphones', Rarity.Common, 'Not designed for horses. More of a fashion statement.', 15, 629, 538, 104, 124),
        '1bikehelmet': new ItemData('Motorbike Helmet', Rarity.Uncommon, 'Would be extremely protective, except it doesn\'t fit on the horse.', 25, 640, 528, 221, 294),
        '1boppers': new ItemData('Head Boppers', Rarity.Uncommon, 'The horse entered a race for life and destroyed the competition.', 20, 636, 540, 83, 235),
        '1clownwig': new ItemData('Clown Wig', Rarity.Uncommon, 'Perfect for children\'s parties.', 20, 624, 544, 289, 233),
        '1cowboy': new ItemData('Cowboy Hat', Rarity.Uncommon, 'Yeehaw, cowboy!', 20, 634, 538, 244, 212),
        '1crown': new ItemData('Crown', Rarity.Uncommon, 'I\'m not a monarchist, but...', 20, 642, 572, 190, 294),
        '1poker': new ItemData('Poker Cap', Rarity.Uncommon, 'Why do they wear these?', 25, 645, 548, 231, 154),
        '1shuttershades': new ItemData('Shutter Shades', Rarity.Uncommon, 'Shutter shades x Brexit. Literally only a cunt would wear them.', 20, 639, 536, 243, 118),
        '1skigoggles': new ItemData('Ski Goggles', Rarity.Uncommon, 'For hanging out with the bourgeoisie on the Swiss Alps.', 25, 620, 526, 254, 128),
        '1snorkel': new ItemData('Snorkel', Rarity.Uncommon, 'Incredibly important for oft underseas adventures.', 30, 631, 460, 317, 364),
        '1surgicalmask': new ItemData('Surgical Mask', Rarity.Uncommon, 'Even horses do their part to protect the NHS.', 20, 641, 454, 249, 230),
        '1weldingmask': new ItemData('Welding Mask', Rarity.Uncommon, 'Custom made for the horse for all those pesky welding jobs it does.', 30, 622, 448, 303, 312),
        '2cogs': new ItemData('Cogs', Rarity.Rare, 'Make your horse part-clockwork!', 30, 690, 500, 152, 222),
        '2druidcrown': new ItemData('Druid Crown', Rarity.Rare, 'Feel the thrill of pretending you\'re magical!', 40, 658, 552, 194, 188),
        '2eggnog': new ItemData('Eggnog Hat', Rarity.Rare, 'Never again be without the \'nog.', 40, 626, 453, 243, 367),
        '2eggshell': new ItemData('Egg Shell', Rarity.Rare, 'Came from a really big bird. Not the sesame street one.', 40, 626, 528, 256, 214),
        '2playboy': new ItemData('Playboy Ears', Rarity.Rare, 'Hugh Hefner has a secret, sexy horse.', 30, 618, 529, 254, 342),
        '2sawblade': new ItemData('Saw Blade', Rarity.Rare, 'It\'s a miracle the horse is still alive!', 35, 698, 476, 193, 256),
        '3divinghelmet': new ItemData('Diving Helmet', Rarity.Epic, 'Big Daddy has seen better days.', 50, 537, 408, 409, 474),
        '3jason': new ItemData('Jason Mask', Rarity.Epic, 'A friendly face from Camp Crystal Lake.', 55, 596, 481, 299, 283),
        '3spacehelmet': new ItemData('Space Helmet', Rarity.Epic, 'I think this might be a kid\'s toy...', 45, 586, 448, 342, 365),
        '3tengu': new ItemData('Tengu Mask', Rarity.Epic, 'Culturally appropriate to put on a horse.', 55, 670, 495, 272, 272),
        '4penicorn': new ItemData('The Penicorn', Rarity.Legendary, 'A most majestic beast.', 85, 706, 564, 211, 150),
        '4stinger': new ItemData('The Stinger', Rarity.Legendary, 'Always keep it 69.', 200, 615, 468, 242, 264)
    },
    'headback': {
        'none': new ItemData('None'),
        '0headphones': ItemData.box(654, 577, 98, 107),
        '1boppers': ItemData.box(683, 527, 166, 254),
        '1snorkel': ItemData.box(742, 444, 65, 89),
    },
    'neck': {
        'none': new ItemData('None'),
        '0scarf': new ItemData('Scarf', Rarity.Common, 'Keeps your neck warm.', 10, 452, 232, 365, 494),
        '0sharktooth': new ItemData('Shark Tooth Necklace', Rarity.Common, 'Sick necklace. Shaka, brah.', 10, 502, 251, 179, 437),
        '0tieblue': new ItemData('Blue Tie', Rarity.Common, 'Fresh from a job interview.', 5, 509, 218, 229, 511),
        '0tiegreen': new ItemData('Green Tie', Rarity.Common, 'Fresh from a job interview.', 5, 509, 218, 229, 511),
        '0tiered': new ItemData('Red Tie', Rarity.Common, 'Fresh from a job interview.', 5, 509, 218, 229, 511),
        '1belttaekwondo': new ItemData('Taekwondo Belt', Rarity.Uncommon, 'Your horse knows karat- uh. Taekwondo!', 25, 510, 287, 249, 413),
        '1beltwrestling': new ItemData('Wrestling Belt', Rarity.Uncommon, 'The horse broke into the ring unscripted and stole the belt for itself.', 20, 505, 370, 252, 268),
        '1clown': new ItemData('Clown Bow Tie', Rarity.Uncommon, '"Impress" children everywhere!', 15, 512, 358, 368, 279),
        '1daisychain': new ItemData('Daisy Chain', Rarity.Uncommon, 'Made by a creative school kid or a low level cultist.', 20, 513, 363, 257, 321),
        '1frenchhorn': new ItemData('French Horn', Rarity.Uncommon, 'The horse can\'t actually play.', 25, 484, 156, 312, 619),
        '1goldchain': new ItemData('Gold Chain', Rarity.Uncommon, 'Big chain for a big horse.', 15, 498, 294, 218, 372),
        '1hose': new ItemData('Garden Hose', Rarity.Uncommon, 'The nozzle keeps coming off and leaking everywhere.', 15, 493, 360, 348, 409),
        '1poolring': new ItemData('Pool Ring', Rarity.Uncommon, 'Keep your head above water.', 25, 463, 320, 422, 500),
        '2choker': new ItemData('Choker', Rarity.Rare, 'Definitely someone\'s kink. Maybe mine.', 35, 528, 382, 219, 274),
        '2cogs': new ItemData('Cogs', Rarity.Rare, 'Make your horse part-clockwork!', 30, 521, 357, 264, 400),
        '2garlic': new ItemData('Garlic Necklace', Rarity.Rare, 'Keep the French at bay.', 30, 522, 335, 262, 382),
        '2harpoon': new ItemData('Harpoon', Rarity.Rare, 'Accidents happen.', 40, 555, 246, 386, 705),
        '2neckbrace': new ItemData('Neck Brace', Rarity.Rare, 'Turned its neck a bit too quickly.', 35, 507, 380, 332, 292),
        '2tyre': new ItemData('Tyre', Rarity.Rare, 'The result of a nasty car accident. In front of the horse.', 40, 465, 304, 537, 659),
        '3bread': new ItemData('Bread Slice', Rarity.Epic, 'You\'ve seen bread cat, now see bread horse!', 55, 446, 253, 554, 690),
        '3chaosemeralds': new ItemData('Chaos Emeralds', Rarity.Epic, 'All systems, full power!', 50, 496, 389, 368, 324),
        '3fire': new ItemData('Ring of Fire', Rarity.Epic, 'The horse fell in.', 50, 407, 323, 660, 687),
        '3slinky': new ItemData('Slinky', Rarity.Epic, 'A spring, a spring, a marvelous thing!', 45, 516, 389, 314, 345),
        '4chalkboard': new ItemData('Chalkboard', Rarity.Legendary, 'Says "I\'m horny" on it. The horse is very forward.', 90, 533, 212, 300, 641),
        '4newbalance': new ItemData('New Balance Necklace', Rarity.Legendary, 'Gettin\' stingy with it.', 180, 477, 230, 296, 486),
    },
    'body': {
        'none': new ItemData('None'),
        '0figleaf': new ItemData('Fig Leaf', Rarity.Common, 'Have some modesty.', 10, 182, 307, 340, 324),
        '0saddleblack': new ItemData('Black Saddle', Rarity.Common, 'Suitable for a horse.', 5, 314, 268, 361, 448),
        '0saddlebrown': new ItemData('Brown Saddle', Rarity.Common, 'Suitable for a horse.', 5, 314, 268, 361, 448),
        '1bodyarmor': new ItemData('Body Armour', Rarity.Uncommon, 'Protects you from kicks to the loin, flank and croup.', 20, 205, 274, 680, 447),
        '1kilt': new ItemData('Kilt', Rarity.Uncommon, 'Traditional wear for a horse.', 20, 144, 276, 598, 409),
        '1lasercannon': new ItemData('Laser Cannon', Rarity.Uncommon, 'Underbarrelled laser cannon, grafted to the horse. Absolutely not stolen from Star Citizen.', 30, 235, 214, 957, 308),
        '1magnet': new ItemData('Magnet', Rarity.Uncommon, 'Sticks to the body with electrifying results. Unsure which part of the horse is magnetic.', 20, 270, 310, 380, 468),
        '1poolring': new ItemData('Pool Ring', Rarity.Uncommon, 'Makes your horse infinitely more buoyant and fun! It\'s absolutely massive.', 25, 246, 157, 501, 835),
        '1rocket': new ItemData('Rocket', Rarity.Uncommon, 'A toy rocket strapped to the horse\'s back with rope. May or may not be filled with gasoline.', 25, 108, 301, 860, 570),
        '1satellite': new ItemData('Satellite Dish', Rarity.Uncommon, 'Ideal for catching pesky enemy communications or Sky TV channels.', 20, 226, 468, 421, 399),
        '1scuba': new ItemData('Scuba Tank', Rarity.Uncommon, 'Land horses are out, sea horses are in.', 20, 291, 294, 458, 469),
        '1tutu': new ItemData('Tutu Skirt', Rarity.Uncommon, 'Against all odds, the horse is adept at ballet.', 25, 214, 181, 412, 791),
        '2boombox': new ItemData('Boombox', Rarity.Rare, 'I can\'t get no sleep.', 30, 281, 458, 354, 270),
        '2cactus': new ItemData('Cactus', Rarity.Rare, 'Maybe you should put it in a pot.', 40, 270, 376, 441, 642),
        '2cogs': new ItemData('Cogs', Rarity.Rare, 'Make your horse part-clockwork!', 30, 329, 341, 323, 227),
        '2lemmings': new ItemData('Lemmings', Rarity.Rare, 'Made in Scotland, don\'t you know.', 40, 274, 130, 417, 684),
        '2persianrug': new ItemData('Persian Rug', Rarity.Rare, 'Give your horse a touch of class.', 35, 209, 199, 590, 544),
        '2pipes': new ItemData('Pipes', Rarity.Rare, 'Where do they go? Nobody knows.', 30, 329, 341, 323, 227),
        '2tv': new ItemData('TV', Rarity.Rare, 'The horse got a bit too into The Teletubbies.', 40, 320, 318, 354, 282),
        '3angelwings': new ItemData('Angel Wings', Rarity.Epic, 'It did hurt when the horse fell from heaven.', 55, 138, 209, 936, 678),
        '3beewings': new ItemData('Bee Wings', Rarity.Epic, 'Makes an incredibly loud buzzing noise.', 50, 154, 414, 860, 407),
        '3fishtank': new ItemData('Fish Tank', Rarity.Epic, 'Trips to the vet are very confusing.', 50, 185, 136, 684, 910),
        '3smallhorse': new ItemData('Small Horse', Rarity.Epic, 'Just a small guy.', 60, 309, 457, 432, 434),
        '4puppetshow': new ItemData('Puppet Show', Rarity.Legendary, 'Punch and Judy find a brand new stage.', 80, 165, 134, 638, 973),
        '469belt': new ItemData('69 Belt', Rarity.Legendary, '69 and unprotected.', 170, 257, 317, 149, 335),
    },
    'legs': {
        'none': new ItemData('None'),
        '0boots': new ItemData('Boots', Rarity.Common, 'Hardy shoes for rough environments. Useless to a horse.', 10, 147, 15, 769, 258),
        '0kneepads': new ItemData('Knee Pads', Rarity.Common, 'Important for netball practice.', 10, 175, 137, 643, 230),
        '0socks': new ItemData('Socks', Rarity.Common, 'Keep your hooves warm and fashionable.', 15, 175, 43, 624, 235),
        '1ankleweights': new ItemData('Ankle Weights', Rarity.Uncommon, 'If the horse didn\'t have these it would be too powerful.', 20, 164, 91, 654, 203),
        '1armbands': new ItemData('Arm Bands', Rarity.Uncommon, 'Could potentially capsize the horse.', 25, 155, 163, 753, 276),
        '1belts': new ItemData('Belts', Rarity.Uncommon, 'Anime was a mistake.', 25, 173, 40, 698, 470),
        '1clownshoes': new ItemData('Clown Shoes', Rarity.Uncommon, 'I went to clown school and all I got were these lowsy shoes.', 20, 164, 20, 833, 172),
        '1crocs': new ItemData('Crocs', Rarity.Uncommon, 'Extremely practical for a horse. Only comes in pink.', 20, 163, 20, 798, 189),
        '1cymbals': new ItemData('Cymbals', Rarity.Uncommon, 'You can really, really hear it coming.', 20, 394, 106, 322, 388),
        '1flippers': new ItemData('Flippers', Rarity.Uncommon, 'Like a horse out of water.', 20, 173, 20, 863, 168),
        '1skis': new ItemData('Skis', Rarity.Uncommon, 'Speeds up your horse on slopes.', 20, 284, 20, 877, 120),
        '1tights': new ItemData('Tights', Rarity.Uncommon, 'Can\'t a girl feel good about her legs?', 25, 176, 41, 656, 482),
        '2cogs': new ItemData('Cogs', Rarity.Rare, 'Make your horse part-clockwork! This one is in the crotch.', 30, 232, 246, 267, 180),
        '2cowboyboots': new ItemData('Cowboy Boots', Rarity.Rare, 'Your wife don\'t understand you, but I do.', 30, 158, 16, 756, 340),
        '2jeans1': new ItemData('Jeans 1', Rarity.Rare, 'It\'s all a matter of opinion, really.', 35, 175, 90, 394, 731),
        '2jeans2': new ItemData('Jeans 2', Rarity.Rare, 'It\'s all a matter of opinion, really.', 35, 170, 85, 816, 604),
        '2rollerskates': new ItemData('Roller Skates', Rarity.Rare, 'Be the coolest horse at the disco!', 40, 151, 26, 805, 272),
        '2wheel': new ItemData('Wheel', Rarity.Rare, 'A wheely big wheel.', 35, 153, 99, 823, 828),
        '3extralegs': new ItemData('Extra Legs', Rarity.Epic, 'Always carry a few spares.', 60, 303, 26, 716, 555),
        '3pegleg': new ItemData('Peg Leg', Rarity.Epic, 'Yarrrrrr. It be taken by a land shark.', 50, 173, 61, 151, 438),
        '3sorashoes': new ItemData('Sora\'s Shoes', Rarity.Epic, 'Simple and clean is the way that you\'re making me feel tonight.', 55, 135, 18, 976, 370),
        '3tubes': new ItemData('Cardboard Tubes', Rarity.Epic, 'We\'ve all done it once.', 60, 143, 21, 939, 607),
        '4624s': new ItemData('624s', Rarity.Legendary, 'Got my 624s on.', 190, 141, 23, 846, 251),
    },
    'special': {
        'none': new ItemData('None'),
        '0moustache': new ItemData('Moustache', Rarity.Common, 'Looking dandy.', 15, 722, 490, 139, 42),
        '0pinkbow': new ItemData('Pink Bow', Rarity.Common, 'Very pretty. Ties around the horse\'s tail.', 15, 145, 408, 180, 136),
        '1carouselpole': new ItemData('Carousel Pole', Rarity.Uncommon, 'Not entirely ethical when attached to a real horse.', 20, 390, 185, 108, 865),
    },
    'skin': {
        '0beige': new ItemData('Beige', Rarity.Common, 'Basic paint job. Why would you choose... beige?', 2, 164, 27, 1076, 1080),
        '0black': new ItemData('Black', Rarity.Common, 'Basic paint job.', 2, 164, 27, 1076, 1080),
        '0blue': new ItemData('Blue', Rarity.Common, 'Basic paint job.', 2, 164, 27, 1076, 1080),
        '0bluelight': new ItemData('Light Blue', Rarity.Common, 'Basic paint job.', 2, 164, 27, 1076, 1080),
        '0brown': new ItemData('Brown', Rarity.Common, 'Basic paint job.', 2, 164, 27, 1076, 1080),
        '0green': new ItemData('Green', Rarity.Common, 'Basic paint job.', 2, 164, 27, 1076, 1080),
        '0greenlight': new ItemData('Light Green', Rarity.Common, 'Basic paint job.', 2, 164, 27, 1076, 1080),
        '0grey': new ItemData('Grey', Rarity.Common, 'Basic paint job.', 2, 164, 27, 1076, 1080),
        '0orange': new ItemData('Orange', Rarity.Common, 'Basic paint job.', 2, 164, 27, 1076, 1080),
        '0pink': new ItemData('Pink', Rarity.Common, 'Basic paint job.', 2, 164, 27, 1076, 1080),
        '0purple': new ItemData('Purple', Rarity.Common, 'Basic paint job.', 2, 164, 27, 1076, 1080),
        '0red': new ItemData('Red', Rarity.Common, 'Basic paint job.', 2, 164, 27, 1076, 1080),
        '0redlight': new ItemData('Light Red', Rarity.Common, 'Basic paint job.', 2, 164, 27, 1076, 1080),
        '0turquoise': new ItemData('Turquoise', Rarity.Common, 'Basic paint job.', 2, 164, 27, 1076, 1080),
        '0white': new ItemData('White', Rarity.Common, 'Basic paint job.', 2, 164, 27, 1076, 1080),
        '0yellow': new ItemData('Yellow', Rarity.Common, 'Basic paint job.', 2, 164, 27, 1076, 1080),
        '1brick': new ItemData('Brick', Rarity.Uncommon, 'Built like a... well you get it.', 10, 164, 27, 1076, 1080),
        '1camo': new ItemData('Camouflage', Rarity.Uncommon, 'Like Arnold fighting the Predator.', 10, 164, 27, 1076, 1080),
        '1fabriccotton': new ItemData('Cotton', Rarity.Uncommon, 'Crocheted by someone\'s nan.', 10, 164, 27, 1076, 1080),
        '1fabricfelt': new ItemData('Felt', Rarity.Uncommon, 'A child\'s school project. Looks shit.', 10, 164, 27, 1088, 1093),
        '1fabricleather': new ItemData('Leather', Rarity.Uncommon, 'Be warned of riding this horse in the nude.', 10, 164, 27, 1076, 1080),
        '1fabrictartan': new ItemData('Tartan', Rarity.Uncommon, 'Can almost smell this one.', 15, 164, 27, 1076, 1080),
        '1marble': new ItemData('Marble', Rarity.Uncommon, 'The paint wore off with time.', 5, 164, 27, 1076, 1080),
        '1metalrusty': new ItemData('Rusty Metal', Rarity.Uncommon, 'Left out in the rain too long.', 10, 164, 27, 1076, 1080),
        '1metaltextured': new ItemData('Textured Metal', Rarity.Uncommon, 'Good grip in a slippery environment.', 10, 164, 27, 1076, 1080),
        '1plasticrecycled': new ItemData('Recycled Plastic', Rarity.Uncommon, 'Recycled at least 5 times.', 5, 164, 27, 1076, 1080),
        '1rock': new ItemData('Rock', Rarity.Uncommon, 'Somehow a natural formation.', 5, 164, 27, 1076, 1080),
        '1woodbark': new ItemData('Bark', Rarity.Uncommon, 'You\'d be a sap to buy this.', 5, 164, 27, 1076, 1080),
        '1woodoak': new ItemData('Oak', Rarity.Uncommon, 'Fresh from the workshop.', 5, 164, 27, 1076, 1080),
        '1woodpine': new ItemData('Pine', Rarity.Uncommon, 'Only sold in Ikea.', 5, 164, 27, 1076, 1080),
        '2animalbee': new ItemData('Bee', Rarity.Rare, 'Busy as a bee! To bee or not to bee! Don\'t worry, bee happy!', 30, 164, 27, 1076, 1080),
        '2animalcow': new ItemData('Cow', Rarity.Rare, 'Moore or less correct.', 30, 164, 27, 1076, 1080),
        '2animaltiger': new ItemData('Tiger', Rarity.Rare, 'Significantly less threatening.', 30, 164, 27, 1076, 1080),
        '2animalzebra': new ItemData('Zebra', Rarity.Rare, 'Close enough.', 30, 164, 27, 1076, 1080),
        '2honeycomb': new ItemData('Honeycomb', Rarity.Rare, 'Honey, I\'m comb! Is literally dripping with honey.', 35, 164, 27, 1076, 1080),
        '2papergrid': new ItemData('Grid Paper', Rarity.Rare, 'Smells like my secondary school maths notebook.', 35, 164, 27, 1076, 1080),
        '2paperlined': new ItemData('Lined Paper', Rarity.Rare, 'Taaaaake ooooon meeeeeee! Taaaaaake meeeeee oooooon!', 35, 164, 27, 1076, 1080),
        '2petrified': new ItemData('Petrified', Rarity.Rare, 'A horse with a stone cold stare. Head somehow slid off.', 30, 164, 20, 1067, 1085),
        '2polka': new ItemData('Polka Dot', Rarity.Rare, 'Apt to make you violently ill.', 25, 164, 27, 1076, 1080),
        '2slut': new ItemData('Whorse', Rarity.Rare, 'Someone scrawled on the horse. Swedish for "final".', 25, 164, 27, 1076, 1080),
        '2terminator': new ItemData('The Terminator', Rarity.Rare, 'I\'ll be back.', 20, 164, 27, 1076, 1080),
        '3flesh': new ItemData('Flesh', Rarity.Epic, 'A work of the flesh.', 50, 164, 27, 1076, 1080),
        '3glasspainted': new ItemData('Painted Glass', Rarity.Epic, 'A very peculiar sort of window.', 45, 164, 27, 1076, 1080),
        '3rainbow': new ItemData('Rainbow', Rarity.Epic, 'Is the light hitting it from somewhere?', 50, 164, 27, 1076, 1080),
        '3volcanic': new ItemData('Volcanic', Rarity.Epic, 'Fresh from the crust. Still hot to the touch.', 55, 126, 24, 1210, 1152),
        '3worldmap': new ItemData('World Map', Rarity.Epic, 'Mr. Worldwide!', 55, 164, 27, 1076, 1080),
        '3yakuza': new ItemData('Yakuza', Rarity.Epic, 'The horse with the dragon tattoo.', 45, 164, 27, 1076, 1080),
        '4cloud': new ItemData('Cloud', Rarity.Legendary, 'Lighter than air.', 70, 129, 27, 1143, 1090),
        '4grass': new ItemData('Grass', Rarity.Legendary, 'You are what you eat.', 65, 146, 27, 1122, 1080),
        '4transparent': new ItemData('Transparent', Rarity.Legendary, 'We forgot to fill this one in.', 75, 164, 27, 1076, 1080),
    },
    'background': {
        'none': new ItemData('Transparent'),
        '0tickets': new ItemData('Tickets Pattern', Rarity.Common, 'Show your allegience to the ket ticket brand!', 2),
        '069pattern': new ItemData('69 Pattern', Rarity.Common, 'A subtle flavour of 69.', 2),
        '0693d': new ItemData('3D 69', Rarity.Common, 'A less subtle flavour of 69.', 2),
        '1partiboi00': new ItemData('Bathtubboi69', Rarity.Uncommon, 'Collect them all! (#1)', 4),
        '1partiboi01': new ItemData('Bathtubboi69 #2', Rarity.Uncommon, 'Collect them all! (#2)', 4),
        '1partiboi02': new ItemData('Beltboi69', Rarity.Uncommon, 'Collect them all! (#3)', 4),
        '1partiboi03': new ItemData('Bikeboi69', Rarity.Uncommon, 'Collect them all! (#4)', 4),
        '1partiboi04': new ItemData('Technoboi69', Rarity.Uncommon, 'Collect them all! (#5)', 4),
        '1partiboi05': new ItemData('Smartboi69', Rarity.Uncommon, 'Collect them all! (#6)', 4),
        '1partiboi06': new ItemData('Arrestedboi69', Rarity.Uncommon, 'Collect them all! (#7)', 4),
        '1partiboi07': new ItemData('Flameboi69', Rarity.Uncommon, 'Collect them all! (#8)', 4),
        '1partiboi08': new ItemData('Dogboi69', Rarity.Uncommon, 'Collect them all! (#9)', 4),
        '1partiboi09': new ItemData('Spaceboi69', Rarity.Uncommon, 'Collect them all! (#10)', 4),
        '1partiboi10': new ItemData('Horseflowboi69', Rarity.Uncommon, 'Collect them all! (#11)', 4),
        '1partiboi11': new ItemData('Arcadeboi69', Rarity.Uncommon, 'Collect them all! (#12)', 4),
        '1partiboi12': new ItemData('Nudeboi69', Rarity.Uncommon, 'Collect them all! (#13)', 4),
        '1partiboi13': new ItemData('Horseboi69', Rarity.Uncommon, 'Collect them all! (#14)', 4),
        '1partiboi14': new ItemData('Angelboi69', Rarity.Uncommon, 'Collect them all! (#15)', 4),
        '1partiboi15': new ItemData('Cinemaboi69', Rarity.Uncommon, 'Collect them all! (#16)', 4),
        '1partiboi16': new ItemData('Ghettoboi69', Rarity.Uncommon, 'Collect them all! (#17)', 4),
        '1partiboi17': new ItemData('Tekkenboi69', Rarity.Uncommon, 'Collect them all! (#18)', 4),
        '1partiboi18': new ItemData('Discoboi69', Rarity.Uncommon, 'Collect them all! (#19)', 4),
        '1partiboi19': new ItemData('Beachboi69', Rarity.Uncommon, 'Collect them all! (#20)', 4),
        '1partiboi20': new ItemData('Relaxedboi69', Rarity.Uncommon, 'Collect them all! (#21)', 4),
        '1partiboi21': new ItemData('Pirateboi69', Rarity.Uncommon, 'Collect them all! (#22)', 4),
        '1partiboi22': new ItemData('Sexboi69', Rarity.Uncommon, 'Collect them all! (#23)', 4),
        '1partiboi23': new ItemData('Twintailboi69', Rarity.Uncommon, 'Collect them all! (#24)', 4),
        '1partiboi24': new ItemData('Konmydboi69', Rarity.Uncommon, 'Collect them all! (#25)', 4),
    }
}

export function getHeadBack(id: string): string {
    const headback = itemData['headback']

    if ( id in headback )
        return id
    else
        return 'none'
}

export function getShadow(id: string): string {
    switch ( id ) {
        case '2petrified':
            return 'petrified'
        default:
            return 'default'
    }
}

export function getRandomRareItem(): [string, string] {
    const getRareItems = (category: string): [string, string][] => {
        const keys = Object.keys(itemData[category])
        return keys.filter(key => key.startsWith('2', 0) && key != '2petrified').map(key => [category, key])
    }

    const head = getRareItems('head')
    const neck = getRareItems('neck')
    const body = getRareItems('body')
    const legs = getRareItems('legs')
    const special = getRareItems('special')
    const skin = getRareItems('skin')
    const background = getRareItems('background')

    const rareItems = head.concat(neck, body, legs, special, skin, background)

    const index = Math.floor(Math.random() * rareItems.length);
    return rareItems[index]
}

export default itemData;
