import { DatabaseBase } from "./database"

export class ShopId {
    constructor(
        public id: string = '',
        public type: string = '',
        public sale: number = 0
    ){}
}

export class ShopInventory implements Iterable<ShopId> {
    constructor(
        public item0: ShopId,
        public item1: ShopId,
        public item2: ShopId,
        public item3: ShopId,
        public item4: ShopId,
        public item5: ShopId,
        public item6: ShopId,
        public item7: ShopId
    ){}

    public get(index: number) : ShopId {
        index = Math.floor(index)

        switch (index) {
            case 0:
                return this.item0
            case 1:
                return this.item1
            case 2:
                return this.item2
            case 3:
                return this.item3
            case 4:
                return this.item4
            case 5:
                return this.item5
            case 6:
                return this.item6
            case 7:
                return this.item7
            default:
                throw RangeError(`Index out of bounds: ${index}`)
        }
    }

    [Symbol.iterator]() : Iterator<ShopId> {
        let counter = 0

        return {
            next: () => {
                const result = {
                    done: counter > 7,
                    value: counter > 7 ? new ShopId() : this.get(counter)
                }

                ++counter;
                return result;
            }
        }
    }
}

export default class Shop {
    public static publicShopInventory(db: DatabaseBase): ShopInventory {
        const shopItems = db.shopItems

        const items = [...Array(8).keys()].map(i => {
            return new ShopId(
                shopItems.items[i],
                shopItems.types[i],
                shopItems.sales[i])
        })

        return new ShopInventory(
            items[0],
            items[1],
            items[2],
            items[3],
            items[4],
            items[5],
            items[6],
            items[7]
        )
    }

    public static get generalShopItems(): { [type: string]: ShopId[] } {
        return {
            'head': [
                new ShopId('0headphones', 'head'),
                new ShopId('0bandana', 'head'),
            ],
            'neck': [
                new ShopId('0tiered', 'neck'),
                new ShopId('0tiegreen', 'neck'),
                new ShopId('0tieblue', 'neck'),
                new ShopId('0sharktooth', 'neck'),
                new ShopId('0scarf', 'neck'),
            ],
            'body': [
                new ShopId('0saddlebrown', 'body'),
                new ShopId('0saddleblack', 'body'),
                new ShopId('0figleaf', 'body'),
            ],
            'legs': [
                new ShopId('0socks', 'legs'),
                new ShopId('0kneepads', 'legs'),
                new ShopId('0boots', 'legs'),
            ],
            'special': [
                new ShopId('0pinkbow', 'special'),
                new ShopId('0moustache', 'special'),
            ],
            'skin': [
                new ShopId('0yellow', 'skin'),
                new ShopId('0white', 'skin'),
                new ShopId('0turquoise', 'skin'),
                new ShopId('0redlight', 'skin'),
                new ShopId('0red', 'skin'),
                new ShopId('0purple', 'skin'),
                new ShopId('0pink', 'skin'),
                new ShopId('0orange', 'skin'),
                new ShopId('0grey', 'skin'),
                new ShopId('0greenlight', 'skin'),
                new ShopId('0green', 'skin'),
                new ShopId('0bluelight', 'skin'),
                new ShopId('0blue', 'skin'),
                new ShopId('0black', 'skin'),
                new ShopId('0beige', 'skin'),
            ],
            'background': [
                new ShopId('0tickets', 'background'),
                new ShopId('069pattern', 'background'),
                new ShopId('0693d', 'background'),
            ]
        }
    }

    private static get horseMessages(): { [type: string]: string[] } {
        return {
            'greeting': [
                'What\'re ya buyin\'?',
                'I only saw items like these in ketamine dreams.',
                'This store is 100% unprotected.',
                'New Balance wouldn\'t sponsor us. Please buy generously.',
                'This stuff will make you dip on the dance floor.',
                'Wanna make that booty flow? Shop now!',
                'I actually know Horseflow. Buy at my store and I\'ll teach you too!',
                'Warning: These prices may kick or bite.',
                'Did you know? I\'m about as good at sex as I am at karate.',
                'These prices are a wet dream.',
                'I\'m the king of pleasure and these prices are very pleasurable.',
            ],
            'general': [
                'The basic stock is it? Well alright then...',
                'No, no. This stuff is good too. Probably.',
                'Really? You don\'t want the special items?',
                'Well you could buy from here too... If you want...',
                'Oh this stuff? Really? Big loser energy coming from you right now.',
                'Your vibes are kind of off man you should check the specials out instead.'
            ],
            'sale': [
                'Stranger, stranger, stranger... Now THAT\'S an item!',
                'Heh heh heh... Thank you!',
                'You sure know a lot about horse style!',
                'That was a deeply unprotected purchase.',
                'I feel it I\'m... I\'m entering the Sting Zone!',
                'You\'ve got that pussy on tap!',
                'Nice buy! Hit me up if you wanna 69.'
            ],
            'no sale': [
                'Someone is trying to spend outside of their means...',
                'You CAN NOT afford that. Step AWAY from the item.',
                'You trying to mug me off?!',
                'Back dat a$$ up and clear off.'
            ]
        }
    }

    private static randomMessage(type: string): string {
        const messages = this.horseMessages[type]
        const index = Math.floor(Math.random() * messages.length)
        return messages[index]
    }

    public static get randomGreetingMessage(): string {
        return this.randomMessage('greeting')
    }

    public static get randomGeneralMessage(): string {
        return this.randomMessage('general')
    }

    public static get randomSaleMessage(): string {
        return this.randomMessage('sale')
    }

    public static get randomNoSaleMessage(): string {
        return this.randomMessage('no sale')
    }
}
